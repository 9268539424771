

























































import Vue from 'vue'

export default Vue.extend({
  name: 'v-tooltip-btn',
  props: {
    hint: String,
    btnText: String,
    tile: Boolean,
    fab: Boolean,
    rounded: Boolean,
    shaped: Boolean,
    disabled: Boolean,
    depressed: Boolean,
    outlined: Boolean,
    height: {
      type: [Number, String],
      default: undefined
    },
    width: {
      type: [Number, String],
      default: undefined
    },
    elevation: {
      type: [Number, String],
      default: undefined
    },
    to: {
      type: [String, Object],
      default: undefined
    },
    href: {
      type: [String, Object],
      default: undefined
    },
    btnIcon: String,
    iconColor: String,
    tooltipPosition: {
      type: String,
      default: 'top',
      validator: (v: string) => ['top', 'bottom'].includes(v)
    },
    action: {
      type: [String],
      default: undefined
    }
  },
  computed: {
    computedTop (): boolean {
      return !!(!this.tooltipPosition || this.tooltipPosition === 'top')
    },
    computedBottom (): boolean {
      return !this.computedTop
    },
    hasIcon (): boolean {
      return !!this.btnIcon
    },
    hasText (): boolean {
      return !!this.btnText && this.hasIcon
    },
    hasTooltip (): boolean {
      return this.hint.length > 0
    }
  },
  methods: {
    BtnClick () {
      this.$emit('click', this.action)
    }
  }
})
