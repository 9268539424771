(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vuetify/lib"), require("vue"));
	else if(typeof define === 'function' && define.amd)
		define(["vuetify/lib", ], factory);
	else if(typeof exports === 'object')
		exports["vuetify-toolkit"] = factory(require("vuetify/lib"), require("vue"));
	else
		root["vuetify-toolkit"] = factory(root["vuetify/lib"], root["Vue"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__4875__, __WEBPACK_EXTERNAL_MODULE__8bbf__) {
return 